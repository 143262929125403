<template>
	<v-container>
		<v-row justify="center" align="center">
			<v-col cols="12" xl="8" md="8" sm="6" xs="12">
        <v-card align="justify" >
          <v-card-title primary-title>
            Contacto.
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="Nombre"
                  dense
                  name="name"
                  label="Nombre"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="Email"
                  dense
                  name="name"
                  label="Email"
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="Telefono"
                  dense
                  name="name"
                  label="Teléfono"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="Empresa"
                  dense
                  name="name"
                  label="Empresa"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea label="Mensaje" v-model="Mensaje" outlined dark/>
              </v-col>
              <v-col cols="12">
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="validar">Enviar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
		</v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      logo: '',
      email1: '',
      email2: '',
      primario: '',
      secundario: '',

      Nombre: '',
      Telefono: '',
      Email: '',
      Empresa: '',
      Mensaje: '',
      fecha: new Date().toISOString().substr(0, 10),

      snackbar: false,
      msg: '',

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Correo no valido'
        }
      }
    }
  },

  created () {
    this.init()
  },

  computed: {
    ...mapGetters('Login', ['getdatosUsuario', 'getClientes', 'getCliente', 'getCIA', 'getInfoVend', 'getModo']),
    ...mapGetters('Login', ['getCIA'])

  },

  methods: {
    init () {
      this.$http.get('api/v1/logos.list').then(response => {
        this.logo = response.body.Logourl
      }, error => {
        console.log(error)
      })

      this.$http.get('api/v1/email.list').then(response => {
        this.email1 = response.body.Email1
        this.email2 = response.body.Email2
      })

      this.$http.get('api/v1/tema.list/' + 2).then(respuesta => {
        this.primario = respuesta.body.primario
        this.secundario = respuesta.body.secundario
      })
    },

    // Validamos que todos los campos esten llenos
    validar () {
      if (this.Nombre != '' && this.Telefono != '' && this.Email != '' && this.Empresa != '' && this.Mensaje != '') {
        // Llamamos la función de agregar el prospecto "addProspecto"
        this.addProspecto()
      } else {
        // Mostramos un mensaje para decir que fatan campos para llenar
        this.msg = 'Completa los campos'
        this.snackbar = true
      }
    },

    addProspecto () {
      var payload = {
        Nombre: this.Nombre,
        Telefono: this.Telefono,
        Correo: this.Email,
        Empresa: this.Empresa,
        Comentarios: this.Mensaje,
        Hora: this.hora(),
        Fecha: this.fecha
      }

      this.$http.post('api/v1/prospectos', payload).then(response => {
        this.sendEmail()
      })
    },

    sendEmail () {
      var payload = {
        Nombre: this.Nombre,
        Email: this.Email,
        Cprimario: this.primario,
        Csecundario: this.secundario,
        Logo: this.logo,
        nomempresa: this.getCIA.nomcia,
        Email1: this.email1,
        Email2: this.email2
      }

      this.$http.post('api/v1/sendprospecto', payload).then(response => {
        this.msg = 'Correo enviado'
        this.snackbar = true
        this.limpiar()
      })
    },

    limpiar () {
      this.Nombre = ''
      this.Telefono = ''
      this.Email = ''
      this.Empresa = ''
      this.Mensaje = ''
    },

    hora () {
      var f = new Date()
      var hora = f.getHours()
      var minuto = f.getMinutes()
      var segundo = f.getSeconds()
      if (hora < 10) {
        hora = '0' + f.getHours()
      }
      if (minuto < 10) {
        minuto = '0' + f.getMinutes()
      }
      if (segundo < 10) {
        segundo = '0' + f.getSeconds()
      }
      return hora + ':' + minuto + ':' + segundo
    }
  }
}
</script>
